import { attr, belongsTo, hasMany } from '@ember-data/model';
import { FALLBACK_COVER_URL, SECTION_CONTENT_TYPES } from '@mvb/tix-ui/constants';
import { generatePseudoIdentifier } from '@mvb/tix-ui/models/section';
import { tracked } from '@glimmer/tracking';
import Model from './-base';
import sortBy from 'lodash-es/sortBy';

export default class SectionContentModel extends Model {
  @attr('string', {
    defaultValue() {
      return generatePseudoIdentifier();
    },
  })
  anchorId;
  @attr() body;
  @attr() caption;
  @attr() contentType;
  @attr() contributorId;
  @attr() copyright;
  @attr('number') count;
  @attr() credit;
  @attr() headline;
  @attr('string') advertisingMediumIsbn;
  @attr() advertisingMediumIsFree;
  @attr('string') advertisingMediumArticleNumber;
  @attr('number') position;
  @attr() resourceContentType;
  @attr() resourceForm;
  @attr() supportingResourceId;
  @attr() referencedProductId;
  @attr() referencedProductIsbn;
  @attr() resourceMode;
  @attr() textContentId;
  @attr() thumbnailUrl;
  @attr() webToPrintTemplate;
  @attr('string', FALLBACK_COVER_URL) urlContent;
  // This property is only declared and used in the FE to help handling highlights (see serializers/section-content)
  @attr() highlights;

  @belongsTo('contributor', { async: false, inverse: null }) contributor;
  @belongsTo('custom-marketing-content', { async: false, inverse: null }) customMarketingContent;
  @belongsTo('file', { async: false, inverse: null }) file;
  @belongsTo('search-product', { async: false, inverse: null }) searchProduct;
  @belongsTo('section', { async: false, inverse: 'contents' }) section;

  @hasMany('price', { async: false, inverse: 'sectionContent' }) prices;
  // FIXME: These two properties are obsolete and should be removed once the backend is updated
  @hasMany('listing-recommendation', { async: true, inverse: null }) listingRecommendations;
  @hasMany('listing-recommendation-hugendubel', { async: true, inverse: null }) hugListingRecommendations;

  @tracked markedForDeletion = false;

  get isVLBContent() {
    return this.contentType === SECTION_CONTENT_TYPES.VLB;
  }

  get contributorType() {
    return this.contributor?.get('type');
  }

  get ean() {
    return this.section?.get('ean');
  }

  get sortedListingRecommendations() {
    return sortBy(this.customMarketingContent?.listingRecommendations ?? [], 'position');
  }

  get sortedListingRecommendationsHugendubel() {
    return sortBy(this.customMarketingContent?.hugListingRecommendations ?? [], 'position');
  }

  get sectionType() {
    return this.section?.get('type');
  }
}
