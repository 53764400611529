import { all, task } from 'ember-concurrency';
import { TIX_ITEM_SOURCE_TYPE, TIX_LIST_TYPE } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import ModalAddToLists from '@mvb/tix-ui/components/lists/modal/add-to-lists';
import ModalUpdateLists from '@mvb/tix-ui/components/lists/modal/update-lists';
import Service, { service } from '@ember/service';

export function processResponse(lists, results) {
  let itemOnListByListId = new Map();

  for (let { listId, itemOnList } of lists) {
    itemOnListByListId.set(listId, itemOnList);
  }

  if (results) {
    for (let { listId, itemOnList } of results) {
      itemOnListByListId.set(listId, itemOnList);
    }
  }

  let onList = [...itemOnListByListId.values()].some(Boolean);

  return { results, lists, itemOnList: itemOnListByListId, onList };
}

export default class ProductListService extends Service {
  @service api;
  @service errors;
  @service features;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service progress;
  @service router;
  @service store;

  async addProductsToList(total, filter) {
    let selectedElementsCount = this.manageList.determineSelectedElementsCount(total);

    if (selectedElementsCount === 0) {
      this.modals.notify({
        message: this.intl.t('productsIndex.notification.noSelectionForList'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    } else {
      let taskInstance = this.availableProductListsTask.unlinked().perform();
      let [, selectedListIds] = await all([
        taskInstance,
        this.modals.open(ModalAddToLists, {
          type: TIX_LIST_TYPE.PRODUCT_LIST,
          load: () => taskInstance,
        }),
      ]);

      if (!Array.isArray(selectedListIds) || selectedListIds.length === 0) {
        return false;
      }

      let message = this.features.isEnabled('manage-lists-in-modal')
        ? this.intl.t('listsModalAddProductsToList.text.plural.progress')
        : this.intl.t('listsModalAddProductsToList.text.singular.progress');
      let progress = this.progress.add({
        message,
      });

      let results = await this.api.postJSON('/product-lists', {
        filters: {
          ...filter,
        },
        identifiers: [...this.manageList.selectedItems],
        invertedSelection: this.manageList.inverse,
        targetIds: selectedListIds,
      });

      if (Array.isArray(results)) {
        for (let { productId, onList } of results) {
          this.store.pushRecordAttributes('product', productId, { onList });
          this.store.pushRecordAttributes('search-product', productId, { onList });
        }
      }

      progress.remove();
      let successMessage = this.features.isEnabled('manage-lists-in-modal')
        ? this.intl.t('listsModalAddProductsToList.notification.plural.success')
        : this.intl.t('listsModalAddProductsToList.notification.singular.success');
      this.notifications.success(successMessage);
    }
  }

  async updateListsForProduct(product, preview) {
    let taskInstance = this.productListsTask.unlinked().perform(product);

    let [lists, results] = await all([
      taskInstance,
      this.modals.open(ModalUpdateLists, {
        item: product,
        type: TIX_LIST_TYPE.PRODUCT_LIST,
        load: () => taskInstance,
        update: async (product_, summaries) => {
          let data = {
            summaries,
          };

          if (preview) {
            data.sourceId = preview.id;
            data.sourceType = TIX_ITEM_SOURCE_TYPE.PREVIEW;
          }

          return this.api.postJSON(`/product-lists/${product_.id}`, data);
        },
      }),
    ]);

    let result = processResponse(lists, results);
    let { onList } = result;

    this.store.pushRecordAttributes('product', product.id, { onList });
    this.store.pushRecordAttributes('search-product', product.id, { onList });

    return result;
  }

  async deleteProductsFromList(total, filter, listId) {
    if (this.areNoElementsSelected(total)) {
      this.modals.notify({
        message: this.intl.t('productsIndex.notification.noSelectionForListDelete'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    } else if (await this.deleteModalWasConfirmed()) {
      let progress = this.progress.add({
        message: this.intl.t('listsModalDeleteProductsFromList.text.progress'),
      });

      await this.api.deleteJSON('/product-lists', {
        identifiers: [...this.manageList.selectedItems],
        invertedSelection: this.manageList.inverse,
        filters: {
          ...filter,
        },
        targetId: listId,
      });

      this.router.refreshCurrentRoute();

      progress.remove();
      this.notifications.success(this.intl.t('listsModalDeleteProductsFromList.notification.success'));
    }
  }

  @task
  @waitFor
  *availableProductListsTask() {
    return yield this.api.get('/product-lists');
  }

  @task
  @waitFor
  *productListsTask(product) {
    return yield this.api.get(`/product-lists/${product.id}`);
  }

  areNoElementsSelected(total) {
    return this.manageList.determineSelectedElementsCount(total) === 0;
  }

  deleteModalWasConfirmed() {
    return this.modals.confirm({
      title: this.intl.t('listsModalDeleteProductsFromList.text.title'),
      message: this.intl.t('listsModalDeleteProductsFromList.text.message'),
      confirm: this.intl.t('listsModalDeleteProductsFromList.action.confirm'),
      cancel: this.intl.t('listsModalDeleteProductsFromList.action.cancel'),
    });
  }
}
