import { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { NOTE_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Model from './-base';
import sortBy from 'lodash-es/sortBy';

export default class NoteModel extends Model {
  @service features;

  @attr('date-time') created;
  @attr('date-time') lastUpdated;

  @attr('string') text;

  @belongsTo('user', { async: false, inverse: null }) creator;
  @belongsTo('party', { async: false, inverse: null }) party;
  @belongsTo('product', { async: false, inverse: 'notes' }) product;

  @hasMany('group', { async: false, inverse: null }) groups;

  get groupIds() {
    return this.groups?.map((group) => group.id) ?? [];
  }

  get sortedGroupNames() {
    let groupNames = this.groups?.map((group) => group.name) ?? [];
    return sortBy(groupNames, (name) => name.toLowerCase());
  }

  get visibility() {
    let groups = this.groups;
    let party = this.party?.get('id');

    if (this.features.isEnabled('groups')) {
      if (isEmpty(groups) && isEmpty(party)) {
        return NOTE_TYPES.PRIVATE;
      }

      if (groups.length > 0) {
        return NOTE_TYPES.GROUPS;
      }
    }

    return NOTE_TYPES.PARTY;
  }
}
