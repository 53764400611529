import { FACETS } from '@mvb/tix-ui/constants';
import { isNone } from '@ember/utils';
import { service } from '@ember/service';
import Ability from './-base';

export default class SearchAbility extends Ability {
  @service abilities;
  @service branches;
  @service features;
  @service router;
  @service user;

  get filterPreviewsByEditStatus() {
    return this.abilities.can('preview.editStatus');
  }

  get filterPreviewsByCreatedBy() {
    let party = this.user.selectedParty?.party;
    if (party?.isBookstore && !this.features.isEnabled('bookstorePreviews')) {
      return false;
    }

    return (
      party?.isPremium &&
      (party?.isBookGroup || party?.isBookstore || party?.isPublisher || party?.isSalesCoop) &&
      (this.filterPreviewsByCreatedByAnAssignedCompany || this.filterPreviewsByCreatedByUser)
    );
  }

  get filterPreviewsByCreatedByAnAssignedCompany() {
    return this.user.assignedPartiesMvbIds.length > 0;
  }

  get filterPreviewsByCreatedByUser() {
    return !isNone(this.user.current?.id);
  }

  get filterPreviewsByStatus() {
    let party = this.user.selectedParty?.party;
    return party?.isBookGroup || party?.isPublisher || party?.isSalesCoop;
  }

  get filterProductsByAnnouncementDate() {
    let party = this.user.selectedParty?.party;

    // in main product list only visible für publisher or sales-coop, in other places for all users
    return 'products.index' !== this.router.currentRouteName || party?.isPublisher || party?.isSalesCoop;
  }

  get filterProductsByBookstoreBranchId() {
    return (
      this.branches.current.length > 1 && ['order.dispo-lists.dispo-list.index'].includes(this.router.currentRouteName)
    );
  }

  get filterProductsByGenrecode() {
    return !this.filterProductsByPartyGenre;
  }

  get filterProductsByHighlightsBookGroup() {
    let party = this.user.selectedParty?.party;

    return (
      this.features.isEnabled('productFilterHighlightsBookGroup') &&
      (party?.isBookGroup || party?.isMemberOfAnyBookGroup || party?.isPublisher || party?.isSalesCoop)
    );
  }

  get filterProductsByPartyGenre() {
    let party = this.user.selectedParty?.party;

    return party?.isBookstore && this.searchService?.meta?.facets?.some((facet) => facet.name === FACETS.PARTY_GENRE);
  }

  get filterProductsByRetailSale() {
    let party = this.user.selectedParty?.party;

    return party?.isBookGroup || party?.isBookstore || party?.isPublisher || party?.isSalesCoop;
  }

  get filterProductsByListingThalia() {
    return this.searchService?.meta?.facets?.some((facet) => facet.name === FACETS.LISTING_THALIA);
  }

  get filterProductsByWithNotes() {
    return this.abilities.can('note.access');
  }
}
