import { all, task } from 'ember-concurrency';
import { processResponse } from './product-list';
import { TIX_LIST_TYPE } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import ModalAddToLists from '@mvb/tix-ui/components/lists/modal/add-to-lists';
import ModalUpdateLists from '@mvb/tix-ui/components/lists/modal/update-lists';
import Service, { service } from '@ember/service';

export default class PreviewListService extends Service {
  @service api;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service progress;
  @service router;
  @service store;
  @service features;

  async addPreviewsToList(total, filter, sort) {
    if (this.areNoElementsSelected(total)) {
      this.modals.notify({
        message: this.intl.t('previewsIndex.notification.noSelectionForList'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    } else {
      let taskInstance = this.availablePreviewListsTask.perform();
      let [, selectedListIds] = await all([
        taskInstance,
        this.modals.open(ModalAddToLists, {
          type: TIX_LIST_TYPE.PREVIEW_LIST,
          load: () => taskInstance,
        }),
      ]);

      if (!Array.isArray(selectedListIds) || selectedListIds.length === 0) {
        return false;
      }

      let message = this.features.isEnabled('manage-lists-in-modal')
        ? this.intl.t('listsModalAddPreviewsToList.text.plural.progress')
        : this.intl.t('listsModalAddPreviewsToList.text.singular.progress');
      let progress = this.progress.add({
        message,
      });

      let results = await this.api.postJSON('/preview-lists', {
        identifiers: [...this.manageList.selectedItems],
        invertedSelection: this.manageList.inverse,
        filters: {
          ...filter,
        },
        sort,
        targetIds: selectedListIds,
      });

      if (Array.isArray(results)) {
        for (let { previewId, onList } of results) {
          this.store.pushRecordAttributes('preview', previewId, { onList });
          this.store.pushRecordAttributes('search-preview', previewId, { onList });
        }
      }

      progress.remove();
      let successMessage = this.features.isEnabled('manage-lists-in-modal')
        ? this.intl.t('listsModalAddPreviewsToList.notification.plural.success')
        : this.intl.t('listsModalAddPreviewsToList.notification.singular.success');
      this.notifications.success(successMessage);
    }
  }

  async updateListsForPreview(preview) {
    let taskInstance = this.previewListsTask.unlinked().perform(preview);

    let [lists, results] = await all([
      taskInstance,
      this.modals.open(ModalUpdateLists, {
        item: preview,
        type: TIX_LIST_TYPE.PREVIEW_LIST,
        load: () => taskInstance,
        update: (preview_, payload) => this.api.postJSON(`/preview-lists/${preview_.id}`, payload),
      }),
    ]);

    let result = processResponse(lists, results);
    let { onList } = result;

    this.store.pushRecordAttributes('preview', preview.id, { onList });
    this.store.pushRecordAttributes('search-preview', preview.id, { onList });

    return result;
  }

  async deletePreviewsFromList(total, filter, sort, listId) {
    if (this.areNoElementsSelected(total)) {
      this.modals.notify({
        message: this.intl.t('previewsIndex.notification.noSelectionForListDelete'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
    } else if (await this.deleteModalWasConfirmed()) {
      let progress = this.progress.add({
        message: this.intl.t('listsModalDeletePreviewsFromList.text.progress'),
      });

      await this.api.deleteJSON('/preview-lists', {
        identifiers: [...this.manageList.selectedItems],
        invertedSelection: this.manageList.inverse,
        filters: {
          ...filter,
        },
        sort,
        targetId: listId,
      });

      this.router.refreshCurrentRoute();

      progress.remove();
      this.notifications.success(this.intl.t('listsModalDeletePreviewsFromList.notification.success'));
    }
  }

  @task
  @waitFor
  *availablePreviewListsTask() {
    return yield this.api.get('/preview-lists');
  }

  @task
  @waitFor
  *previewListsTask(preview) {
    return yield this.api.get(`/preview-lists/${preview.id}`);
  }

  areNoElementsSelected(total) {
    return this.manageList.determineSelectedElementsCount(total) === 0;
  }

  deleteModalWasConfirmed() {
    return this.modals.confirm({
      title: this.intl.t('listsModalDeletePreviewsFromList.text.title'),
      message: this.intl.t('listsModalDeletePreviewsFromList.text.message'),
      confirm: this.intl.t('listsModalDeletePreviewsFromList.action.confirm'),
      cancel: this.intl.t('listsModalDeletePreviewsFromList.action.cancel'),
    });
  }
}
