import { hash } from 'ember-concurrency';
import { service } from '@ember/service';
import { URL_USER_TOGGLE_HIGHLIGHTS_BHG } from '@mvb/tix-ui/constants';
import Route from '@ember/routing/route';

export default class ProfileSettingsRoute extends Route {
  @service api;

  model() {
    return hash({
      showHighlightsBookGroupsToggle: this.api.get(URL_USER_TOGGLE_HIGHLIGHTS_BHG),
      user: this.modelFor('profile'),
    });
  }
}
