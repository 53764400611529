import Service from '@ember/service';
import window from 'ember-window-mock';

export const TIX_NOTESDRAGGABLE_POSITION = 'tix.notesDraggable.position';

/**
 * Application session storage. Stored in window.sessionStorage, which gets deleted when a window is closed
 * Converts complex objects to JSON strings
 */
export default class SessionStorageService extends Service {
  knownKeys = new Set();

  getItem(key) {
    try {
      return this._parseJSON(window.sessionStorage.getItem(key));
    } catch {
      return undefined;
    }
  }

  setItem(key, value) {
    this.knownKeys.add(key);

    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    try {
      window.sessionStorage.setItem(key, value);
    } catch {
      this.knownKeys.delete(key);
    }
  }

  removeItem(key) {
    try {
      window.sessionStorage.removeItem(key);
      this.knownKeys.delete(key);
    } catch {
      // do nothing
    }
  }

  clearKnownItems() {
    for (let key of this.knownKeys) {
      this.removeItem(key);
    }
  }

  _parseJSON(value) {
    // Skip JSON.parse if value is not a string or an empty sting
    if (!value) {
      return value;
    }

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);

    this.clearKnownItems();
  }

  // Application specific methods

  get notesDraggablePosition() {
    return this.getItem(TIX_NOTESDRAGGABLE_POSITION);
  }

  set notesDraggablePosition(value) {
    if (value === null) {
      this.removeItem(TIX_NOTESDRAGGABLE_POSITION);
    } else {
      this.setItem(TIX_NOTESDRAGGABLE_POSITION, value);
    }
  }
}
