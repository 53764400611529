import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li local-class=\"video\" data-test-video ...attributes>\n  <Layout::Subtitle @title={{this.title}} local-class=\"subtitle\" />\n\n  <button type=\"button\" local-class=\"thumbnail\" data-test-thumbnail {{on \"click\" this.openVideo}}>\n    <img src=\"{{@video.thumbnailUrl}}\" alt={{this.videoTypeString}} {{prevent-contextmenu}} />\n    <FaIcon @icon=\"circle-play\" @size=\"4x\" local-class=\"icon\" />\n  </button>\n\n  <span local-class=\"copyright\" data-test-copyright>\n    {{#if this.copyright}}\n      {{t \"productVideos.text.copyright\" copyright=this.copyright}}\n    {{/if}}\n  </span>\n</li>\n\n{{yield}}", {"contents":"<li local-class=\"video\" data-test-video ...attributes>\n  <Layout::Subtitle @title={{this.title}} local-class=\"subtitle\" />\n\n  <button type=\"button\" local-class=\"thumbnail\" data-test-thumbnail {{on \"click\" this.openVideo}}>\n    <img src=\"{{@video.thumbnailUrl}}\" alt={{this.videoTypeString}} {{prevent-contextmenu}} />\n    <FaIcon @icon=\"circle-play\" @size=\"4x\" local-class=\"icon\" />\n  </button>\n\n  <span local-class=\"copyright\" data-test-copyright>\n    {{#if this.copyright}}\n      {{t \"productVideos.text.copyright\" copyright=this.copyright}}\n    {{/if}}\n  </span>\n</li>\n\n{{yield}}","moduleName":"@mvb/tix-ui/components/product/videos/video.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/product/videos/video.hbs"}});
import { action } from '@ember/object';
import { getAssetTypeTranslationPath } from '@mvb/tix-ui/utils/internal-codelist-services';
import { getVideoUrl } from '@mvb/tix-ui/utils/external-video-services';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import ModalUiVideo from '@mvb/tix-ui/components/ui/modal/video';

export default class ProductVideosVideoComponent extends Component {
  @service errors;
  @service intl;
  @service modals;

  get videoTypeString() {
    let assetTypePath = getAssetTypeTranslationPath(this.args.video.resourceContentType, this.args.video.resourceMode);
    return this.intl.t(assetTypePath);
  }

  get title() {
    return this.args.video.caption || this.videoTypeString;
  }

  get copyright() {
    let credit = this.args.video.credit;
    let copyright = this.args.video.copyright;

    if (!isEmpty(credit)) {
      return credit;
    } else if (!isEmpty(copyright)) {
      return copyright;
    }

    return '';
  }

  @action
  openVideo() {
    this.modals.open(ModalUiVideo, {
      src: getVideoUrl(this.args.video.urlContent),
      text: this.videoTypeString,
      title: this.title,
    });
  }
}
