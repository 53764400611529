import { DateTime } from 'luxon';
import { isEmpty, isPresent } from '@ember/utils';
import applicationUtil from '@mvb/tix-ui/utils/application';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export default function validatePromotionalPackageDates(options = {}) {
  return (key, newValue, oldValue, changes, content) => {
    let newDate = getLuxonDate(newValue);
    let publicationDate = getLuxonDate(changes.publicationDate ?? content.publicationDate);

    if (
      !applicationUtil.hasInstance ||
      isEmpty(newValue) ||
      (typeof newValue === 'string' && newValue.includes('null')) ||
      key === 'publicationDate' ||
      isEmpty(publicationDate)
    ) {
      return true;
    }

    let intlService = applicationUtil.lookup('service:intl');
    let description = options.description ?? key;

    if (!newDate.isValid) {
      return buildMessage(key, {
        type: 'invalid',
        value: newValue,
        context: { description },
      });
    }

    if (newDate < publicationDate) {
      return buildMessage(key, {
        type: 'after',
        value: newValue,
        context: { after: intlService.t('sectionsEditDate.label.publicationDate'), description },
      });
    }

    return true;
  };
}

/**
 *
 * @param {*} date the date to transform (either already luxon date or a string in one of the following formats: yyyy, yyyyMM, yyyyMMdd)
 * @returns a luxon date
 */
function getLuxonDate(date) {
  if (isEmpty(date) || (date?.isLuxonDateTime && date?.isValid)) {
    return date;
  }

  // we need to add this check as for some reason, the date is not always a luxon date (although isLuxonDateTime is true)
  // isValid is a property specific to luxon dates, if it is not present, we need to transform the date
  // this will ensure for already saved dates as onSaleDate to be transformed to luxon date for comparison and validation
  if (date.isLuxonDateTime && !date.isValid && isPresent(date.c)) {
    return DateTime.fromObject(date.c);
  }

  let luxonDate = date;

  if (date.length === 4) {
    // if we only have the year, the full date is always the last day of the year
    luxonDate = DateTime.fromISO(date).endOf('year');
  } else if (date.length === 6) {
    // if we have year and month infos, the full date is always the last day of the given month for the given year
    luxonDate = DateTime.fromISO(date).endOf('month');
  } else if (date.length === 8) {
    // if we have year, month and day, we only need to transform it to a full date
    luxonDate = DateTime.fromISO(date);
  }

  return luxonDate;
}
