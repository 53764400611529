export default {
  "draggable": "_draggable_1oaeda",
  "header": "_header_1oaeda",
  "header-inner": "_header-inner_1oaeda",
  "title": "_title_1oaeda",
  "content": "_content_1oaeda",
  "move": "_move_1oaeda",
  "close": "_close_1oaeda",
  "resize": "_resize_1oaeda"
};
