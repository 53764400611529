export default {
  "header": "_header_1j7uhj",
  "input-container": "_input-container_1j7uhj",
  "validations": "_validations_1j7uhj",
  "label": "_label_1j7uhj",
  "ul": "_ul_1j7uhj",
  "component": "_component_1j7uhj",
  "search-isbns-button": "_search-isbns-button_1j7uhj",
  "container": "_container_1j7uhj",
  "isbn-products-section": "_isbn-products-section_1j7uhj",
  "valid-identifier": "_valid-identifier_1j7uhj",
  "invalid-identifier": "_invalid-identifier_1j7uhj",
  "error-list": "_error-list_1j7uhj _error-list_zhaoi9",
  "max-entries-exceeded": "_max-entries-exceeded_1j7uhj _error_zhaoi9",
  "max-entries-reached": "_max-entries-reached_1j7uhj"
};
