import { PARTY_PERMISSIONS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class extends Ability {
  @service user;

  get deleteHugendubelSuggests() {
    return this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_HUG_CMC);
  }
}
