import { all } from 'ember-concurrency';
import { handlePreviewError, PreviewNotFoundError } from '@mvb/tix-ui/routes/previews/preview';
import { HUGENDUBEL_MVB_ID, SECTION_TYPES } from '@mvb/tix-ui/constants';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

/**
 * When changing the url of this route, remember to adapt the link that is generated for preview-emails in the BE!
 */
export default class PresentationsPreviewRoute extends Route {
  @service categoryOfGoods;
  @service collectionOfGoods;
  @service customMarketingContentLocked;
  @service router;
  @service sessionStorage;
  @service store;
  @service('query-params') queryParamsService;
  @service('search-products-in-preview') searchService;

  queryParams = {
    mvbId: { refreshModel: false },
    qp: { refreshModel: false },
  };

  async model({ mvbId, presentation_preview_id: previewId }) {
    let include = ['sections.contents', 'sections.contents.file', 'sections.previewInfo', 'sender'].join(',');
    let preview;

    try {
      if (mvbId) {
        let previews = await this.store.query('preview', {
          filter: { identifier: previewId, identifierType: 'PROPRIETARY', mvbId },
          include,
        });
        preview = previews?.[0];
      } else {
        preview = await this.store.findRecord('preview', previewId, { include });
      }

      if (!preview) {
        throw new PreviewNotFoundError();
      }

      this.customMarketingContentLocked.getCMCsLockedStatusForPreviewsTask.perform({ previews: [preview] });

      return { preview, previewId, assortmentGroups: [] };
    } catch (error) {
      handlePreviewError(error);
    }
  }

  async afterModel(model) {
    let { mvbId } = this.paramsFor(this.routeName);

    if (mvbId || (model.previewId !== undefined && model.previewId !== model.preview.id)) {
      this.router.replaceWith({ ...model, previewId: model.preview.id }, { queryParams: { mvbId: '' } });
      return;
    }

    if (model.preview.isHugendubel) {
      await all([this.categoryOfGoods.load(HUGENDUBEL_MVB_ID), this.collectionOfGoods.load(HUGENDUBEL_MVB_ID)]);
      model.assortmentGroups = await this.collectionOfGoods.getSortedAssortmentGroups();
    }

    let referenceTitleSection = model.preview.sections?.find(
      (section) => section.type === SECTION_TYPES.REFERENCETITLE
    );

    if (isPresent(referenceTitleSection)) {
      this.searchService.model = model.preview;
      this.searchService.queryParams = this.queryParamsService.currentQueryParamsWithDefaults;

      let query = {
        filter: {
          ...this.searchService.searchQuery.filter,
          selectAll: true,
        },
        include: [
          'customMarketingContent',
          'customMarketingContent.hugListingRecommendations',
          'customMarketingContent.hugMarketingRecommendations',
          'customMarketingContent.listingRecommendations',
          'customMarketingContent.marketingRecommendations',
          'searchProduct',
          'searchProduct.notes',
        ].join(','),
      };

      let filteredSectionContents = await this.store.query('section-content', query);
      referenceTitleSection.set('contents', filteredSectionContents);
    }
  }

  resetController() {
    super.resetController(...arguments);
    this.sessionStorage.notesDraggablePosition = null;
  }

  serialize(model) {
    return {
      presentation_preview_id: model.preview.id,
    };
  }
}
