import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ProfileRoute extends Route {
  @service session;
  @service store;
  @service user;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    if (!this.session.requireAuthentication(transition)) {
      return;
    }
  }

  async model() {
    return this.store.findRecord('user', this.user.current?.id, {
      include: 'address,avatar,registrationInput,blogInfo',
    });
  }
}
