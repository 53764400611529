import { didCancel, hash, task } from 'ember-concurrency';
import { isBlank, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Route from '@ember/routing/route';

export default class PresentationsDispoListRoute extends Route {
  @service intl;
  @service manageList;
  @service presentation;
  @service router;
  @service sessionStorage;
  @service store;
  @service('query-params') queryParamsService;
  @service('search-products-in-dispo-list') searchService;

  queryParams = {
    qp: { refreshModel: false },
  };

  model({ presentation_dispolist_id: dispoListId }) {
    try {
      return hash({
        dispoList: this.store.findRecord('dispo-list', dispoListId),
      });
    } catch {
      this.router.replaceWith('order.dispo-lists.dispo-list', dispoListId);
    }
  }

  async afterModel(model) {
    model.mappedDispoListProducts = await this.loadItemsForPresentation(model);

    if (!isPresent(model.mappedDispoListProducts)) {
      throw new Error(this.intl.t('error.productList.noSelection'));
    }

    // set all products for the presentation mode
    this.presentation.allProducts = model.mappedDispoListProducts;
  }

  resetController() {
    super.resetController(...arguments);
    this.sessionStorage.notesDraggablePosition = null;
  }

  loadItemsForPresentation() {
    return this.searchForPresentationTask.perform(...arguments).catch((error) => {
      if (!didCancel(error)) {
        throw error;
      }
    });
  }

  @task({ restartable: true })
  @waitFor
  *searchForPresentationTask({ dispoList }) {
    this.searchService.model = dispoList;
    this.searchService.queryParams = this.queryParamsService.currentQueryParamsWithDefaults;

    let selectedDispoListProductIds = this.searchService.queryParams.selectedDispoListProductIds;
    let invertedSelection = this.searchService.queryParams.inverse;
    let query = { ...this.searchService.searchQuery };

    query.filter = {
      ...query.filter,
      selectAll: isBlank(selectedDispoListProductIds) || invertedSelection,
      selectedDispoListProductIds,
    };
    delete query.page;

    let dispoListProducts = yield this.store.query('dispo-list-product', query);

    return (
      dispoListProducts.map((dispoListProduct, index) => {
        let productId = dispoListProduct.belongsTo('product').id();
        let searchProduct = dispoListProduct.belongsTo('searchProduct').value();
        return {
          position: index,
          referencedProductId: productId,
          referencedProductIsbn: dispoListProduct.productIsbn,
          searchProduct,
          sourceId: dispoListProduct.sourceId,
          sourceType: dispoListProduct.sourceType,
          thumbnailUrl: dispoListProduct.coverUrl,
        };
      }) ?? []
    );
  }
}
