import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class CompaniesIndexRoute extends Route {
  @service session;
  @service store;

  queryParams = {
    page: {
      refreshModel: false,
    },
    searchTerm: {
      refreshModel: false,
    },
    sort: {
      refreshModel: false,
    },
  };

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.loadData();
  }

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    if (!this.session.requireAuthentication(transition)) {
      return;
    }
  }
}
