import { didCancel, hash, task } from 'ember-concurrency';
import { isBlank, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Route from '@ember/routing/route';

export default class PresentationsProductListRoute extends Route {
  @service intl;
  @service manageList;
  @service presentation;
  @service router;
  @service sessionStorage;
  @service store;
  @service('query-params') queryParamsService;
  @service('search-products-in-list') searchService;

  queryParams = {
    qp: { refreshModel: false },
  };

  model({ presentation_productlist_id: productListId }) {
    try {
      return hash({
        productList: this.store.findRecord('list', productListId),
      });
    } catch {
      this.router.replaceWith('product-lists.product-list', productListId);
    }
  }

  async afterModel(model) {
    model.mappedProductListProducts = await this.loadItemsForPresentation(model);

    if (!isPresent(model.mappedProductListProducts)) {
      throw new Error(this.intl.t('error.productList.noSelection'));
    }

    // set all products for the presentation mode
    this.presentation.allProducts = model.mappedProductListProducts;
  }

  resetController() {
    super.resetController(...arguments);
    this.sessionStorage.notesDraggablePosition = null;
  }

  loadItemsForPresentation() {
    return this.searchForPresentationTask.perform(...arguments).catch((error) => {
      if (!didCancel(error)) {
        throw error;
      }
    });
  }

  @task({ restartable: true })
  @waitFor
  *searchForPresentationTask({ productList }) {
    this.searchService.model = productList;
    this.searchService.queryParams = this.queryParamsService.currentQueryParamsWithDefaults;

    let selectedProductListProductIds = this.searchService.queryParams.selectedProductListProductIds;
    let invertedSelection = this.searchService.queryParams.inverse;
    let query = { ...this.searchService.searchQuery };

    query.filter = {
      ...query.filter,
      selectAll: isBlank(selectedProductListProductIds) || invertedSelection,
      selectedProductListProductIds,
    };
    delete query.page;

    let listItems = yield this.store.query('list-item', query);

    return (
      listItems
        .filter((listItem) => !!listItem.searchProduct)
        .map((listItem, index) => {
          let searchProduct = listItem.belongsTo('searchProduct').value();
          return {
            position: index,
            referencedProductId: listItem.searchProduct.id,
            referencedProductIsbn: listItem.searchProduct.identifier,
            searchProduct,
            sourceId: listItem.sourceId,
            sourceType: listItem.sourceType,
            thumbnailUrl: listItem.searchProduct.coverUrl,
          };
        }) ?? []
    );
  }
}
