import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { PARTY_TYPES_FOR_COMPANY_PAGES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';

export default class CompaniesIndexController extends Controller {
  queryParams = ['page', 'searchTerm', 'sort'];

  @service store;

  @tracked page = 1;
  @tracked sort = 'name';
  @tracked searchTerm = '';

  @tracked publishers;

  @task({ restartable: true })
  @waitFor
  *searchTask() {
    let query = {
      filter: {
        type: PARTY_TYPES_FOR_COMPANY_PAGES,
        active: true,
      },
      page: {
        number: this.page,
      },
    };

    if (this.sort) {
      query.sort = this.sort;
    }

    if (isPresent(this.searchTerm)) {
      query.filter.term = this.searchTerm;
    }

    this.publishers = yield this.store.query('party', query);
  }

  @action onSort() {
    this.page = 1;
    this.loadData();
  }

  @action onSearch(event) {
    event.preventDefault();
    this.page = 1;
    this.loadData();
  }

  @action
  loadData() {
    this.searchTask.perform();
  }
}
