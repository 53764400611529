import { action } from '@ember/object';
import { ASSIGNED_PARTY_PERMISSION, CURRENCIES_DACH, SEARCH_PAGE_SIZES } from '@mvb/tix-ui/constants';
// eslint-disable-next-line import/named
import { cached } from '@glimmer/tracking';
import { didCancel, task, timeout } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import { runInDebug } from '@ember/debug';
import { waitFor } from '@ember/test-waiters';
import Evented from '@ember/object/evented';
import Service, { service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';
import window from 'ember-window-mock';

export default class UserService extends Service.extend(Evented) {
  @service api;
  @service abilities;
  @service errors;
  @service features;
  @service intl;
  @service location;
  @service modals;
  @service progress;
  @service session;
  @service store;

  constructor() {
    super(...arguments);
    window.addEventListener('storage', this.onStorageChange);
  }

  willDestroy() {
    window.removeEventListener('storage', this.onStorageChange);
  }

  get assignedParties() {
    return this.current?.assignedParties ?? [];
  }

  get assignedPartiesMvbIds() {
    return this.assignedParties.map((assignedParty) => assignedParty?.party?.mvbId).filter(Boolean);
  }

  get assignedPartiesPermissionsMap() {
    return this.assignedParties.map((assignedParty) => {
      return {
        id: assignedParty.id,
        mvbId: assignedParty.party?.mvbId,
        permissions: assignedParty.permissionSet?.permissions ?? [],
        permissionSetName: assignedParty.permissionSet?.name,
        premiumStatus: assignedParty.party?.premiumStatus,
      };
    });
  }

  get assignedPartiesSorted() {
    let compareOptions = { numeric: true, sensitivity: 'base' };
    return this.assignedParties.slice().sort((a, b) => {
      let sort = a.party?.name.localeCompare(b.party?.name, compareOptions);

      if (0 === sort) {
        sort = a.party?.mvbId.localeCompare(b.party?.mvbId, compareOptions);
      }

      return sort;
    });
  }

  get canAccessBacklist() {
    return this.abilities.can('backlist.access', this.selectedParty);
  }

  get current() {
    let user = this.userTask.lastSuccessful?.value;
    return user && !user.isDeleted ? user : undefined;
  }

  get currentGroups() {
    return this.updateSelectedPartyGroupsTask.lastSuccessful?.value ?? [];
  }

  get currentUserSettings() {
    return this.userSettingsTask.lastSuccessful?.value ?? {};
  }

  get favoritePublishers() {
    return this.currentUserSettings.favoritePublishers;
  }

  get isAdminOfAnyParty() {
    return this.assignedPartiesPermissionsMap.some(
      (permissionsMap) => permissionsMap?.permissionSetName === ASSIGNED_PARTY_PERMISSION.ADMIN
    );
  }

  get selectedCurrencyCountry() {
    if (isPresent(this.current?.currency) && isPresent(this.current?.currencyCountry)) {
      return `${this.current.currency}_${this.current.currencyCountry}`;
    }

    return CURRENCIES_DACH.EUR_DE;
  }

  @cached
  get selectedParty() {
    if (!this.assignedParties || !this.current) {
      return undefined;
    }

    let selectedId = this.current?.userSettings?.selectedParty?.id;

    return this.assignedParties.find((assignedParty) => assignedParty.id === selectedId);
  }

  get selectedPartyHasPremiumStatus() {
    return this.selectedParty?.isPremium;
  }

  get selectedPartyPermissions() {
    return this.selectedParty?.permissionSet?.permissions ?? [];
  }

  get selectedUserPagesize() {
    return this.currentUserSettings.pageSize ?? SEARCH_PAGE_SIZES[0];
  }

  get useBacklist() {
    return this.current?.userSettings?.backlistChecked;
  }

  @task({ drop: true })
  @waitFor
  *loadTask() {
    yield this.userTask.perform();
    yield this.userSettingsTask.perform();
    // needed for product search filter "highlightsBookGroup"
    yield this.updateSelectedPartyAffiliatesTask.perform();

    // needed for groups in notes
    if (this.features.isEnabled('groups')) {
      yield this.updateSelectedPartyGroupsTask.perform();
    }

    if (!this.current || this.selectedParty || !this.assignedParties?.[0]) {
      return;
    }

    yield this.updateSelectedParty(this.assignedParties[0]);
  }

  @task({ keepLatest: true })
  @waitFor
  *updateBacklistCheckedTask(value) {
    yield timeout(100);

    if (!this.current) {
      return;
    }

    try {
      this.current.userSettings.backlistChecked = value;
      yield this.current.userSettings.save();
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ restartable: true })
  @waitFor
  *updateSelectedPartyAffiliatesTask() {
    try {
      let partyId = this.selectedParty?.party?.id;

      if (partyId) {
        yield this.store.findRecord('party', partyId, {
          include: 'affiliates',
        });
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ restartable: true })
  @waitFor
  *updateSelectedPartyGroupsTask() {
    try {
      let partyId = this.selectedParty?.party?.id;

      if (partyId) {
        let groups = yield this.store.query('group', {
          filter: {
            'party.id': partyId,
            unpaged: true,
          },
        });

        // only return those groups the user is a member of
        let groupsUserIsMemberOf = groups.filter((group) => group.isMember);
        return sortBy(groupsUserIsMemberOf, (group) => group.name.toLowerCase());
      }

      return [];
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ keepLatest: true })
  @waitFor
  *updateSelectedPartyTask(value) {
    this.trigger('selectedPartyWillChange');

    let wait = this.progress.add({
      message: this.intl.t('userService.text.waitingForSelectedPartyChange', {
        party: value.party.name,
      }),
    });

    try {
      this.currentUserSettings.selectedParty = value;
      yield this.currentUserSettings.save();
      yield this.updateSelectedPartyAffiliatesTask.perform();

      if (this.features.isEnabled('groups')) {
        yield this.updateSelectedPartyGroupsTask.perform();
      }

      localStorage.setItem('sync-selected-party', value.id);

      this.location.goToCurrentRoute();
    } catch (error) {
      if (error && error.name !== 'TransitionAborted') {
        this.errors.handle(error);
      }

      wait.remove();
    }

    this.trigger('selectedPartyDidChange');
  }

  @task
  @waitFor
  *userSettingsTask(forceReload) {
    if (!this.current) {
      return;
    }

    if (forceReload) {
      try {
        yield this.current.userSettings.reload();

        return this.current.userSettings;
      } catch (error) {
        this.errors.handle(error);
      }
    }

    return this.current.userSettings;
  }

  @task({ restartable: true })
  @waitFor
  *userTask() {
    try {
      let userId = this.session.data?.authenticated?.userId;

      if (!userId) {
        runInDebug(() => {
          // eslint-disable-next-line no-console
          console.error('Invalid session data in session store');
        });
        this.session.invalidate();
        return;
      }

      return yield this.store.findRecord('user', userId, {
        include: 'assignedParties.party,assignedParties.permissionSet,userSettings',
      });
    } catch {
      this.session.invalidate();
    }
  }

  @action
  onStorageChange(event) {
    if (event.key === 'sync-selected-party' && event.newValue !== this.selectedParty?.id) {
      //synchronize selected party across tabs e.g.
      window.location.reload();
    }
  }

  @action
  updateSelectedParty(value) {
    return this.updateSelectedPartyTask.perform(value);
  }

  anyPartyHasAnyPermission(permissions) {
    return permissions.some((permission) =>
      this.assignedParties?.some((assignedParty) => assignedParty.hasPermission(permission))
    );
  }

  anyPremiumPartyHasAnyPermission(permissions) {
    return permissions.some((permission) =>
      this.assignedParties?.some((assignedParty) => assignedParty.isPremium && assignedParty.hasPermission(permission))
    );
  }

  hasAnyPermission(permissions) {
    return permissions.some((permission) => this.selectedPartyPermissions.includes(permission));
  }

  hasPermission(permission) {
    return this.hasAnyPermission([permission]);
  }

  async load() {
    if (!this.session.isAuthenticated) {
      return;
    }

    try {
      await this.loadTask.perform();
    } catch (error) {
      if (!didCancel(error)) {
        throw error;
      }
    }
  }

  reloadUserSettings() {
    return this.userSettingsTask.perform(true);
  }
}
